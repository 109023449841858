'use strict';

Gri.module({
  name: 'content-alpha',
  ieVersion: null,
  $el: $('.content-alpha'),
  container: '.content-alpha',
  fn: function () {

    // Listen URL HASH //
    var hash = window.location.hash.replace('/', '').replace('#', '');
    try {
      if( $('.tab-pane#' + hash).length > 0) {
        $("html, body").animate({ scrollTop: $('.tab-pane#' + hash).offset().top - 150 }, 1000);
      }
    } catch(err) {}
    // Listen URL HASH //

  }
});
